import { useState } from "react";

const feuerwehr = require("fast_api");
var defaultClient = feuerwehr.ApiClient.instance;
if (
  !process.env.NODE_ENV ||
  process.env.NODE_ENV === "development" ||
  window.location.hostname === "localhost"
) {
  defaultClient.basePath = "http://localhost:8000";
} else {
  defaultClient.basePath = "https://app-test.kfv-lds.de:8143";
}

// disbale setting user-agent header
defaultClient.defaultHeaders = {};

function useAPI() {
  const [apiInstance] = useState({
    Adresse: new feuerwehr.AdresseApi(),
    Beitrag: new feuerwehr.BeitragApi(),
    Datei: new feuerwehr.DateienApi(),
    Feuerwehr: new feuerwehr.FeuerwehrApi(),
    Fortbildung: new feuerwehr.FortbildungApi(),
    Job: new feuerwehr.StellenanzeigenApi(),
    News: new feuerwehr.NewsApi(),
    Nutzer: new feuerwehr.NutzerApi(),
    Teilnehmer: new feuerwehr.TeilnehmerApi(),
    Partnercard: new feuerwehr.PartnerCardApi(),
    Rental: new feuerwehr.RentalItemsApi(),
    Booking: new feuerwehr.RentalBookingsApi(),
    Packages: new feuerwehr.RentalPackagesApi(),
  });

  return {
    apiInstance,
  };
}

export default useAPI;

import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Toolbar,
  Typography,
  Slide,
  useScrollTrigger,
  IconButton,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemIcon,
  Grid,
  useMediaQuery,
} from "@mui/material";
import {
  Menu,
  Home,
  Event,
  School,
  AccountCircle,
  Style,
  HelpOutline,
  Inventory,
  Work,
} from "@mui/icons-material";
import { ReactComponent as FireDepartmentLight } from "./../images/local_fire_department_light.svg";
import { withRouter } from "react-router-dom";

import { AuthContext } from "./Home";
import LoginButton from "./tools/LoginButton";
import AccountButton from "./tools/AccountButton";
import useDivision from "./hooks/useDivision";

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

//Kombination aus Darwer und Appbar; bildet den Rahmen der App
function MainAppFrame(props) {
  const [division, setDivision] = useDivision("kfv");
  const [state, setState] = React.useState({
    open: false,
    type: division.toUpperCase(),
  });

  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const routes = {
    "": "Neuigkeiten",
    fortbildungen: "Fortbildungen",
    kalender: "Kalender",
    feuerwehren: "Feuerwehren",
    login: "Anmelden",
    konto: "Konto",
    impressum: "Impressum",
    datenschutz: "Datenschutzerklärung",
    nutzer: "Nutzerverwaltung",
    partnercard: "Partnercard",
    verleih: "Verleih",
    jobs: "Jobs",
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, open: open });
  };

  const handleListItemClick = (routeTo) => {
    props.history.push(routeTo);
    setState({ ...state, open: false });
  };

  if (props.history.location.pathname === "/setDivision") {
    let query = new URLSearchParams(props.history.location.search);
    let type = query.get("type").toLowerCase();
    if (type === "kfv" || type === "kjf") {
      setDivision(type);
    }
    props.history.push("/");
  }

  const Auth = useContext(AuthContext);

  window.addEventListener("divisionUpdate", () => {
    if (localStorage.getItem("division")) {
      let div = JSON.parse(localStorage.getItem("division")).toUpperCase();
      document.title = `${div}-LDS`;
      setState({ ...state, type: div });
    }
  });

  let version = "<unkown>";
  let appver = document.getElementsByTagName("meta").namedItem("app-version");
  if (appver) {
    version = appver.getAttribute("content");
  }

  return (
    <React.Fragment>
      <HideOnScroll {...props}>
        <AppBar enableColorOnDark>
          <Toolbar>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item>
                <Grid container direction="row" alignItems="center">
                  <IconButton
                    edge="start"
                    onClick={toggleDrawer(true)}
                    color="inherit"
                  >
                    <Menu />
                  </IconButton>
                  <Typography variant="h6">
                    {`${state.type} LDS - ` +
                      routes[props.location.pathname.split("/")[1]]}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                {Auth.isLoggedIn && Auth.user ? (
                  <AccountButton />
                ) : (
                  <LoginButton />
                )}
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
      <SwipeableDrawer
        anchor={"left"}
        open={state.open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
      >
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="space-between"
          sx={{ height: "100%" }}
        >
          <Grid item>
            <List>
              <ListItem
                button
                key={"neuigkeiten"}
                onClick={() => handleListItemClick("/")}
              >
                <ListItemIcon>
                  <Home />
                </ListItemIcon>
                <ListItemText primary={"Neuigkeiten"} />
              </ListItem>
              <ListItem
                button
                key={"Kalender"}
                onClick={() => handleListItemClick("/kalender")}
              >
                <ListItemIcon>
                  <Event />
                </ListItemIcon>
                <ListItemText primary={"Kalender"} />
              </ListItem>
              <ListItem
                button
                key={"Fortbildungen"}
                onClick={() => handleListItemClick("/fortbildungen")}
              >
                <ListItemIcon>
                  <School />
                </ListItemIcon>
                <ListItemText primary={"Fortbildungen"} />
              </ListItem>
              {Auth.isLoggedIn && Auth.user && (
                <ListItem
                  button
                  key={"Partnercard"}
                  onClick={(event) => handleListItemClick("/partnercard")}
                >
                  <ListItemIcon>
                    <Style />
                  </ListItemIcon>
                  <ListItemText primary={"Partnercard"} />
                </ListItem>
              )}
              {Auth.isLoggedIn && Auth.user && (
                <ListItem
                  button
                  key={"Feuerwehren"}
                  onClick={(event) => handleListItemClick("/feuerwehren")}
                >
                  <ListItemIcon>
                    <FireDepartmentLight
                      style={{ fill: prefersDarkMode ? "#C7C7C7" : "#757575" }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={"Feuerwehren"} />
                </ListItem>
              )}
              {Auth.isLoggedIn && Auth.user && (
                <ListItem
                  button
                  key={"Jobs"}
                  onClick={() => handleListItemClick("/jobs")}
                >
                  <ListItemIcon>
                    <Work />
                  </ListItemIcon>
                  <ListItemText primary={"Jobs"} />
                </ListItem>
              )}
              {Auth.isLoggedIn && Auth.user && (
                <ListItem
                  button
                  key={"Verleih"}
                  onClick={() => handleListItemClick("/verleih")}
                >
                  <ListItemIcon>
                    <Inventory />
                  </ListItemIcon>
                  <ListItemText primary={"Verleih"} />
                </ListItem>
              )}
            </List>
            {Auth.isLoggedIn && Auth.user && Auth.user.role === "admin" && (
              <div>
                <Divider orientation="horizontal" />
                <ListItem
                  button
                  key={"Nutzerverwaltung"}
                  onClick={() => handleListItemClick("/nutzer")}
                >
                  <ListItemIcon>
                    <AccountCircle />
                  </ListItemIcon>
                  <ListItemText primary={"Nutzerverwaltung"} />
                </ListItem>
                <ListItem
                  button
                  key={"Verleihverwaltung"}
                  onClick={() => handleListItemClick("/verleih/verwaltung")}
                >
                  <ListItemIcon>
                    <Inventory />
                  </ListItemIcon>
                  <ListItemText primary={"Verleihverwaltung"} />
                </ListItem>
              </div>
            )}
            <Divider />
            <ListItem
              button
              key={"Impressum"}
              onClick={(event) => handleListItemClick("/impressum")}
            >
              <ListItemText primary={"Impressum"} />
            </ListItem>
            <ListItem
              button
              key={"Datenschutzerklärung"}
              onClick={(event) => handleListItemClick("/datenschutz")}
            >
              <ListItemText primary={"Datenschutzerklärung"} />
            </ListItem>
          </Grid>
          <Grid item>
            <center>
              <Typography>{`Version: ${version}`}</Typography>
              <IconButton
                size="small"
                onClick={() => {
                  setState({ ...state, open: false });
                  props.showOnboarding();
                }}
              >
                <HelpOutline />
              </IconButton>
            </center>
          </Grid>
        </Grid>
      </SwipeableDrawer>
    </React.Fragment>
  );
}

export default withRouter(MainAppFrame);
